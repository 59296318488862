import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CommunityDTO } from '../DTO/CommunityDTO';
import { NewDTO } from '../DTO/NewDTO';
import { API_URL } from '../../environments/environment';
import { EsdevenimentDTO } from "../DTO/EsdevenimentDTO";
import { firstValueFrom, Observable, of } from "rxjs";
import { ShareInfoDTO } from '../DTO/ShareInfoDTO';
import { ResetPassRequestDTO } from '../DTO/ResetPassRequestDTO';
import { CommunityModuleDTO } from '../DTO/CommunityModuleDTO';
import { EventFilterDTO } from '../DTO/filters/EventFilterDTO';
import {InscriptionCategoryDTO, InscriptionsDTO} from "../DTO/InscriptionsDTO";
import {CustomFieldDTO} from "../DTO/CustomFieldDTO";
import {CustomFieldValueDTO} from "../DTO/CustomFieldValueDTO";
import {BonificationDTO} from "../DTO/BonificationDTO";
import {RecurrenceDTO} from "../DTO/RecurrenceDTO";
import {InscriptionSentDTO} from "../DTO/InscriptionSentDTO";
import { InscriptionFilterDTO } from '../DTO/filters/InscriptionFilterDTO';
import {TranslateService} from "@ngx-translate/core";
import {PortalDataDTO} from "../DTO/PAM/PortalDataDTO";
import {MandateDTO} from "../DTO/PAM/MandateDTO";
import {AxisDTO} from "../DTO/PAM/AxisDTO";
import {AreaDTO} from "../DTO/PAM/AreaDTO";
import {ODSDTO} from "../DTO/PAM/ODSDTO";
import {TypeDTO} from "../DTO/PAM/typeDTO";
import {ActionDTO} from "../DTO/PAM/ActionDTO";
import { InscriptionTotalPricesDTO } from '../DTO/prices/InscriptionTotalPricesDTO';
import {CenterDTO} from "../DTO/PriorAppointment/CenterDTO";
import {ServiceDTO} from "../DTO/PriorAppointment/ServiceDTO";
import {ReservationDTO} from "../DTO/PriorAppointment/ReservationDTO";
import {
  CheckEnabledDatesDTO, DisabledDaysAndMaxDateDTO,
  FindReservationDTO,
  HoursWithDataDTO,
  PeriodScheduleDTO,
  TimeAvailabilityDTO
} from "../DTO/PriorAppointment/PeriodsAndHoursDto";
import { TicketDTO } from '../DTO/TicketDTO';
import { CategoryDTO } from '../DTO/CategoryDTO';
import { TicketFilterDTO } from '../DTO/filters/TicketFilterDTO';
import { TicketMinDTO } from '../DTO/min/TicketMinDTO';


export var COMMUNITY_CODES_BLACKLIST = [
  'robots',
  'assets',
  'img',
  'apple-app-site-association',
  'apple-touch-icon',
  'apple-touch-icon-precomposed',
  'apple-touch-icon-120x120',
  'apple-touch-icon-120x120-precomposed',
  'wp-content',
  'wp-admin',
  'wp-login',
  'wp-login.php',
  'backup',
  'wordpress',
  'download',
  'undefined',
  'plugins',
  'js',
  'sitemap'
];

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  navchange: EventEmitter<string> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService
    ) { }

  get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  getShareInfo(url: string){
    return this.httpClient.get<ShareInfoDTO>(`${API_URL}/SocialMedia/getShareInfo?uri=${url}`);
  }

  getNews(community: string){
    return this.httpClient.get<NewDTO[]>(`${API_URL}/v2/communities/${community}/news?$orderBy=publishDate%20desc`);
  }

  countNews(community: string){
    return this.httpClient.get<number>(`${API_URL}/v2/communities/${community}/news/count`);
  }

  getNewsPaginated(top: number, skip: number, community: string){
    return this.httpClient.get<NewDTO[]>(`${API_URL}/v2/communities/${community}/news?$orderBy=publishDate%20desc&$top=${top}&$skip=${skip}`);
  }

  getNewById(id: string, community: string){
    return this.httpClient.get<NewDTO>(`${API_URL}/v2/communities/${community}/news/${id}`);
  }

  getCommunityByCode(code: string): Observable<CommunityDTO>{
    if (COMMUNITY_CODES_BLACKLIST.includes(code)){
      return of(null);
    }
    return this.httpClient.get<CommunityDTO>(`${API_URL}/v2/communities/${code}`);
  }

  itunesLookup(appId: string): Observable<any>{
    return this.httpClient.get<CommunityDTO>(`https://itunes.apple.com/es/lookup?id=${appId}&media=software&limit=1`);
  }

  appleAppData(community: string): Observable<any>{
    return this.httpClient.get<CommunityDTO>(`${API_URL}/v2/Communities/${community}/apple-app-data`);
  }

  public getEsdeveniments(community: number, start: Date, end: Date, top: number, skip: number): Observable<EsdevenimentDTO[]> {
    return this.httpClient.get<EsdevenimentDTO[]>(
      `${API_URL}/v2/Communities/${community}/Events?start=${start.toISOString()}&end=${end.toISOString()}&active=true&$orderBy=DateFrom asc&$top=${top}&$skip=${skip}`
    );
  }

  public countEsdeveniments(community: number , start: string, end: string): Observable<number> {
    return this.httpClient.get<number>(
      `${API_URL}/v2/Communities/${community}/Events/count?start=${start}&end=${end}&active=true&$orderBy=DateFrom desc&$top=50`
    );
  }

  public getEsdevenimentBySlug (community: string , slug: string): Observable<EsdevenimentDTO> {
    return this.httpClient.get<EsdevenimentDTO>(
      `${API_URL}/v2/Communities/${community}/Events/${slug}`
    );
  }

  public getEsdevenimentById(community: string, id: number): Observable<EsdevenimentDTO> {
    return this.httpClient.get<EsdevenimentDTO>(
      `${API_URL}/v2/Communities/${community}/Events/${id}`
    );
  }

  emitNavChangeEvent(code: string) {
    this.navchange.emit(code);
  }
  getNavChangeEmitter() {
    return this.navchange;
  }

  public GetGeoTown(id: number): Observable<any> {
    return this.httpClient.get(`${API_URL}/v2/Communities/${id}/geoJson`);
  }

  public checkResetPassword(userid: number, token: string) {
    return this.httpClient.get(`${API_URL}/users/checkTokenValid/${userid}/${token}`);
  }

  public resetPassword(model: ResetPassRequestDTO): Observable<any> {
    return this.httpClient.post<any>(`${API_URL}/users/resetPassword`, model);
  }

  public GetModules(id: number): Observable<CommunityModuleDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${id}/modules`);
  }

  public getEventsCategories(id: number): Observable<any> {
    return this.get(`${API_URL}/Events/${id}/events-categories`);
  }

  public getEventsPaginated(top: number, skip: number, community: string) {
    return this.httpClient.get<NewDTO[]>(`${API_URL}/v2/communities/${community}/news?$orderBy=publishDate%20desc&$top=${top}&$skip=${skip}`);
  }

  public getEventsFiltered(communityCode: string, filters: EventFilterDTO): Observable<EsdevenimentDTO[]> {
    return this.httpClient.post<EsdevenimentDTO[]>(`${API_URL}/v2/Communities/${communityCode}/Events/events-filtered`, filters)
  }

  public countEventsFiltered(communityCode: string, filters: EventFilterDTO): Observable<number> {
    return this.httpClient.post<number>(`${API_URL}/v2/Communities/${communityCode}/Events/events-filtered/count`, filters)
  }

  //#region Tickets

  public GetTicketById(communityCode: string, ticketId: number) {
    return this.get(`${API_URL}/v2/Communities${communityCode}/Modules/Tickets/${ticketId}`);
  }

  public getFilteredTickets(communityCode: string, filters: any, top: number, skip: number): Observable<TicketMinDTO[]> {
    filters = {
      ...filters,
      top,
      skip
    };
    return this.httpClient.post<TicketMinDTO[]>(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets/filtered`, filters)
  }

  public countTickets(communityCode: string, filters: TicketFilterDTO): Observable<number> {
    return this.httpClient.post<number>(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets/filtered/count`, filters)
  }

  //#endregion

  //inscriptions
  public getOneInscription(communityCode: string, id: number): Observable<InscriptionsDTO> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getOneInscription/${id}`);
  }
  public getCustomFields(communityCode: string , id:number ): Observable<CustomFieldDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getCustomFields/${id}`);
  }
  public getCustomFieldsValues(communityCode: string, id: number): Observable<CustomFieldValueDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getCustomFieldsValues/${id}`);
  }
  public getBonificationsInscription(communityCode: string, id: number): Observable<BonificationDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getBonificationsInscriptions/${id}`);
  }
  public getRecurrenceInscription(communityCode: string, id: number): Observable<RecurrenceDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getRecurrencesInscriptions/${id}`);
  }
  public sendInscription(communityCode: string, inscriptionSend: InscriptionSentDTO): Observable<any> {
    return this.httpClient.post<void>(`${API_URL}/v2.1/Communities/${communityCode}/Modules/Inscriptions/SendInscriptionForm`, inscriptionSend);
  }
  public sendMailTowaitingList(communityCode: string, id: number , mail: string): Observable<void> {
    return this.httpClient.get<void>(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/waitingList/${id}/${mail}` );
  }
  public getInscriptionCategories(communityCode: number): Observable<InscriptionCategoryDTO[]> {
    return this.httpClient.get<InscriptionCategoryDTO[]>(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/categories-anonymous`);
  }
  public getInscriptionsFiltered(communityCode: string, filters: InscriptionFilterDTO): Observable<InscriptionsDTO[]>{
    return this.httpClient.post<InscriptionsDTO[]>(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/inscriptions-filtered`,
      filters
    )
  }
  public countInscriptionsFiltered(communityCode: string, filters: InscriptionFilterDTO): Observable<number>{
    return this.httpClient.post<number>(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/inscriptions-filtered/count`,
      filters
    )
  }
  public calcFinalPrice(communityCode: string, inscriptionId: number, inscriptionSent: InscriptionSentDTO) {
    return firstValueFrom(this.httpClient.post<InscriptionTotalPricesDTO>(`${API_URL}/v2.1/Communities/${communityCode}/Modules/Inscriptions/${inscriptionId}/calc-final-price`,
      inscriptionSent
    ))
  }

  //PAM
  public getDataPortal(communityCode: string , mandateId:number): Observable<PortalDataDTO> {
    return this.httpClient.get<PortalDataDTO>(`${API_URL}/v2/Communities/${communityCode}/PAM/PortalData/${mandateId}`);
  }
  public getMandates(communityCode: string): Observable<MandateDTO[]> {
    return this.httpClient.get<MandateDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/Mandates`);
  }
  public getAxis(communityCode: string , mandateId:number): Observable<AxisDTO[]> {
    return this.httpClient.get<AxisDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/Axis/${mandateId}`);
  }
  public getAreas(communityCode: string ): Observable<AreaDTO[]> {
    return this.httpClient.get<AreaDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/Areas/`);
  }
  public getODS(communityCode: string ): Observable<ODSDTO[]> {
    return this.httpClient.get<ODSDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/ODS/`);
  }
  public getTypes(communityCode: string ): Observable<TypeDTO[]> {
    return this.httpClient.get<TypeDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/Types/`);
  }
  getActionDetail(actionId:number , communityCode: string): Observable<any> {
    return this.httpClient.get<AxisDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/ActionDetail/${actionId}`);
  }
  getAxisDetail(axisId:number , communityCode: string): Observable<any> {
    return this.httpClient.get<AxisDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/AxisDetail/${axisId}`);
  }
  public getAreaDetail(communityCode: string, id:number ): Observable<AreaDTO> {
    return this.httpClient.get<AreaDTO>(`${API_URL}/v2/Communities/${communityCode}/PAM/AreaDetail/${id}`);
  }
  public getActionsByArea(communityCode: string, id:number ): Observable<ActionDTO[]> {
      return this.httpClient.get<ActionDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/ActionsByArea/${id}`);
  }
  public getTypeDetail(communityCode: string, id:number ): Observable<TypeDTO> {
    return this.httpClient.get<TypeDTO>(`${API_URL}/v2/Communities/${communityCode}/PAM/TypeDetail/${id}`);
  }
  public getActionsByType(communityCode: string, id:number ): Observable<ActionDTO[]> {
    return this.httpClient.get<ActionDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/ActionsByType/${id}`);
  }
  public getOdsDetail(communityCode: string, id:number ): Observable<ODSDTO> {
    return this.httpClient.get<ODSDTO>(`${API_URL}/v2/Communities/${communityCode}/PAM/ODSDetail/${id}`);
  }
  public getActionsByOds(communityCode: string, id:number ): Observable<ActionDTO[]> {
    return this.httpClient.get<ActionDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PAM/ActionsByOds/${id}`);
  }

  //priorAppointment
  public getCenters(communityCode: string ): Observable<CenterDTO[]> {
    return this.httpClient.get<CenterDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Centers`);
  }
  public getServicesByCenterId(communityCode: string,centerId: number ): Observable<ServiceDTO[]> {
    return this.httpClient.get<ServiceDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Centers/${centerId}/Services`);
  }
  public sendReservation(communityCode: string, reservation: ReservationDTO): Observable<any> {
    return this.httpClient.post<any>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Reservations`, reservation , {responseType: 'text' as 'json' })
  }
  public getDatesReserved(communityCode: string, serviceId: number ): Observable<string[]> {
    return this.httpClient.get<string[]>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Services/${serviceId}/StartTimes`);
  }
  public deleteReservation(communityCode: string, reservation: ReservationDTO ): Observable<void> {
    return this.httpClient.post<void>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Reservations/Delete/`, reservation);
  }
  public getReservationDetail(communityCode: string, reservationSearch: FindReservationDTO): Observable<ReservationDTO> {
    return this.httpClient.post<ReservationDTO>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/FindReservations/` , reservationSearch);
  }
  public getScheduleObject(communityCode: string, serviceId: number): Observable<PeriodScheduleDTO []> {
    return this.httpClient.get<PeriodScheduleDTO []>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Services/${serviceId}/GroupDaysOfWeekByPeriodArray`);
  }
  public generateAvailableTimes(communityCode: string, hoursWithData: HoursWithDataDTO): Observable<TimeAvailabilityDTO[]> {
    return this.httpClient.post<TimeAvailabilityDTO[]>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Reservations/AvalibleTimes`, hoursWithData)
  }
  public disabledDates(communityCode: string, checkEnabledDates: CheckEnabledDatesDTO): Observable<DisabledDaysAndMaxDateDTO> {
    return this.httpClient.post<DisabledDaysAndMaxDateDTO>(`${API_URL}/v2/Communities/${communityCode}/PriorAppointment/Reservations/DisabledDates`, checkEnabledDates)
  }

  //Tickets

  public getTickets(communityCode: string): Observable<TicketDTO[]> {
    return this.httpClient.get<TicketDTO[]>(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets`);
  }

  //

  public getCategoriesByModule(communityId: number, moduleType: string) {
    return this.httpClient.get<CategoryDTO[]>(`${API_URL}/CommunityModulesCategories/${communityId}/module?moduleType=${moduleType}`);
  }

}
