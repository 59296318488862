<ng-container [ngSwitch]="type">

  <!-- TEXT -->

  <ng-container *ngSwitchCase="InputTypes.TEXT">
    <app-custom-field-text
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-text>
  </ng-container>

  <!-- CHECKBOX -->

  <ng-container *ngSwitchCase="InputTypes.CHECKBOX">
    <app-custom-field-checkbox
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-checkbox>
  </ng-container>

  <!-- TEXTAREA -->

  <ng-container *ngSwitchCase="InputTypes.TEXTAREA">
    <app-custom-field-textarea
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-textarea>
  </ng-container>

  <!-- NUMERIC -->

  <ng-container *ngSwitchCase="InputTypes.NUMERIC">
    <app-custom-field-numeric
      #input="ngModel"
      [readonly]="readonly"
      class="w-100"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-numeric>
  </ng-container>

  <!-- NIF -->

  <ng-container *ngSwitchCase="InputTypes.DNI">
    <app-custom-field-nif
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-nif>
  </ng-container>

  <!-- IBAN -->

  <ng-container *ngSwitchCase="InputTypes.IBAN">
    <app-custom-field-iban
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-iban>
  </ng-container>

  <!-- CALENDAR -->

  <ng-container *ngSwitchCase="InputTypes.DATE">
    <app-custom-field-calendar
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)"
      [hasTime]="false">
    </app-custom-field-calendar>
  </ng-container>

  <ng-container *ngSwitchCase="InputTypes.DATETIME">
    <app-custom-field-calendar
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)"
      [hasTime]="true">
    </app-custom-field-calendar>
  </ng-container>

  <!-- DROPDOWN -->

  <ng-container *ngSwitchCase="InputTypes.DROPDOWN">
    <app-custom-field-dropdown
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      [customField]="customField"
      (ngModelChange)="updateChanges($event, customField?.dropdownType);">
    </app-custom-field-dropdown>
  </ng-container>

  <!-- FILE -->

  <ng-container *ngSwitchCase="InputTypes.FILE">
    <app-custom-field-file
      #input="ngModel"
      [readonly]="readonly"
      [customField]="customField"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-file>
  </ng-container>

  <!-- PHONE -->

  <ng-container *ngSwitchCase="InputTypes.PHONE">
    <app-custom-field-phone
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-phone>
  </ng-container>

  <!-- MAIL -->

  <ng-container *ngSwitchCase="InputTypes.EMAIL">
    <app-custom-field-email
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-email>
  </ng-container>

  <!-- DEFAULT -->

  <ng-container *ngSwitchDefault>
    <app-custom-field-text
      #input="ngModel"
      [readonly]="readonly"
      [(ngModel)]="internalValue"
      (ngModelChange)="updateChanges($event)">
    </app-custom-field-text>
  </ng-container>

</ng-container>
