<div class="step-2-wrapper">
    <div class="banner-resum border rounded p-3" [style.background-color]="community?.color1 ? _themeService.getColorWithOpacity(community.color1, 0.2) : 'white'">
        <h2 class="fw-600">{{ 'PRIORAPPOINTMENTFINISH.summary' | translate }}</h2>
        <div class="row">
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.center' | translate }}: </span> <span> {{reservation.centerName}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.type' | translate }}: </span> <span> {{reservation.type === 0 ? ('PRIORAPPOINTMENTFINISH.inPerson' | translate) : ('PRIORAPPOINTMENTFINISH.online' | translate)}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.date' | translate }}: </span> <span> {{reservation.startTime | date: "dd/MM/yy"}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.hour' | translate }}: </span> <span> {{reservation.startTime | date: "hh:mm"}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.service' | translate }}:</span>  <span> {{reservation.serviceName}}</span> </div>
            <div class="col-12 col-md-6 mb-2 " *ngIf="reservation.codeIdentifier"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.codeIdentifier' | translate }}: </span> <span class="fw-600 bg-white p-2 rounded"> {{reservation.codeIdentifier}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.name' | translate }}: </span> <span> {{reservation.name}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.surname' | translate }}: </span> <span> {{reservation.surname}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.email' | translate }}: </span> <span> {{reservation.mail}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.phone' | translate }}: </span> <span> {{reservation.phone}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.idNumber' | translate }}: </span> <span> {{reservation.numberDocumentation}}</span> </div>
            <div class="col-12 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.details' | translate }}: </span> <span> {{reservation.observations}}</span> </div>
            <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
                <app-button-loading [customClass]="'bg-white rounded py-1 px-3 me-4'"
                                    [text]="'PRIORAPPOINTMENTFINISH.download' | translate"
                                    [icon]="'fa fa-download'"
                                    [actionAsync]="_export()">
                </app-button-loading>

                <div *ngIf="itsUpdating">
                    <button class="btn bg-danger border rounded text-white" (click)="deleteReservation()">
                        <i class="fa fa-trash me-2"> </i> <span>{{ 'PRIORAPPOINTMENTFINISH.cancel' | translate }}</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="d-none" #content_pdf *ngIf="this._translate.currentLang == 'ca'" style="background-color: rgba(82, 157, 255, 0.2); padding: 20px; letter-spacing: 1px">
    <div style="text-align: center; margin-bottom: 20px;">
        <img style="width: 50px; margin-bottom: 10px" [src]="community.image | imageUrl" />
        <h2 style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 18px; color: #4a90e2; letter-spacing: 1px">Resúm de la cita</h2>
        <h3 style="font-family: Montserrat, sans-serif; font-weight: 00; font-size: 16px; letter-spacing: 1px"> CODI: {{this.reservation.codeIdentifier}}</h3>
    </div>

    <table style="width: 100%; font-family: Montserrat, sans-serif; line-height: 1.6; font-size: 12px; color: #333; border-collapse: collapse;">
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Centre:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px;">{{reservation.centerName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Tipus:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.type === 0 ? 'Presencial' : 'Online'}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Servei:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.serviceName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Data:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.startTime | date: "dd/MM/yy hh:mm"}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Nom:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.name}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Cognoms:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.surname}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Correu electrònic:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.mail}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Telèfon:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.phone}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Número d’identificació:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.numberDocumentation}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Detalls i observacions:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.observations}}</td>
        </tr>
    </table>

    <div style="text-align: center; margin-top: 20px; font-size: 10px; color: #999;">
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.name}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.mail}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.phone}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.locationString}}</p>
    </div>
</div>

<div class="d-none" #content_pdf *ngIf="this._translate.currentLang == 'es'" style="background-color: rgba(82, 157, 255, 0.2); padding: 20px; letter-spacing: 1px">
    <div style="text-align: center; margin-bottom: 20px;">
        <img style="width: 50px; margin-bottom: 10px" [src]="community.image | imageUrl" />
        <h2 style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 18px; color: #4a90e2; letter-spacing: 1px">Resumen de la cita</h2>
    </div>

    <table style="width: 100%; font-family: Montserrat, sans-serif; line-height: 1.6; font-size: 12px; color: #333; border-collapse: collapse;">
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Centro:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px;">{{reservation.centerName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Tipo:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.type === 0 ? 'Presencial' : 'Online'}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Servicio:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.serviceName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Fecha:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.startTime | date: "dd/MM/yy hh:mm"}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Nombre:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.name}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Apellidos:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.surname}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Correo electrónico:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.mail}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Teléfono:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.phone}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Número de identificación:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.numberDocumentation}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Detalles y observaciones:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.observations}}</td>
        </tr>
    </table>

    <div style="text-align: center; margin-top: 20px; font-size: 10px; color: #999;">
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.name}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.mail}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.phone}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.locationString}}</p>
    </div>
</div>
<div class="d-none" #content_pdf *ngIf="this._translate.currentLang == 'en'" style="background-color: rgba(82, 157, 255, 0.2); padding: 20px; letter-spacing: 1px">
    <div style="text-align: center; margin-bottom: 20px;">
        <img style="width: 50px; margin-bottom: 10px" [src]="community.image | imageUrl" />
        <h2 style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 18px; color: #4a90e2; letter-spacing: 1px">Appointment Summary</h2>
    </div>

    <table style="width: 100%; font-family: Montserrat, sans-serif; line-height: 1.6; font-size: 12px; color: #333; border-collapse: collapse;">
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Center:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px;">{{reservation.centerName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Type:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.type === 0 ? 'In-Person' : 'Online'}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Service:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.serviceName}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Date:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.startTime | date: "dd/MM/yy hh:mm"}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Name:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.name}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Surname:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.surname}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Email:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.mail}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Phone:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.phone}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Identification Number:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.numberDocumentation}}</td>
        </tr>
        <tr>
            <td style="width: 50%; padding: 8px; font-weight: 600; background-color: #f0f0f0;letter-spacing: 1px">Details and Observations:</td>
            <td style="width: 50%; padding: 8px;letter-spacing: 1px">{{reservation.observations}}</td>
        </tr>
    </table>

    <div style="text-align: center; margin-top: 20px; font-size: 10px; color: #999;">
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.name}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.mail}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.phone}}</p>
        <p style="font-family: Montserrat, sans-serif; font-weight: 600; font-size: 10px; letter-spacing: 1px">{{community.locationString}}</p>
    </div>
</div>





