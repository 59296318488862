<div class="event-card" [class.disabled-card]="model.disabled">
    <div class="image-box">
        <img [src]="model.image ?? defaultImg | imageUrl" >
        <div class="featured-star" *ngIf="model.featured">
            <i class="fa fa-star"></i>
        </div>
        <div class="category" *ngIf="model.categoryId">
            <span>{{ model.categoryName }}</span>
        </div>
        <!--<div class="free-banner" *ngIf="isFree || !event.ticketId">
            <span>{{ 'TICEKTS.FreeEvent' | translate }}</span>
        </div>-->
    </div>
    <div class="info-box">
        <div class="event-title">
            <span>{{ model.name }}</span>
        </div>
        <div class="data-box" [style]="isCustomCommunity ? 'justify-content: flex-end;' : ''">
            <div class="when" *ngIf="!isCustomCommunity">
                <ng-container *ngIf="sameDay">
                    <span *ngIf="model.allDay">
                        <i class="calendar-icon"></i>
                        {{ model.dateFrom | localizedDate: 'EEE d MMM yyyy'}}
                    </span>
                    <span *ngIf="!model.allDay">
                        <i class="calendar-icon"></i>
                        {{ model.dateFrom | localizedDate: "EEE d MMM yyyy" }}, {{ model.dateFrom | date: 'HH:mm' }} h
                    </span>
                </ng-container>
                <ng-container *ngIf="!sameDay">
                    <span>
                        <i class="calendar-icon"></i>
                        {{
                        model.allDay ?
                        (model.dateFrom | localizedDate: 'EEE dd MMM')
                        :
                        (model.dateFrom | localizedDate: 'EEE dd MMM HH:mm') + ' h'
                        }}
                    </span>
                    <ng-container *ngIf="model.dateTo">
                        &nbsp;
                        <span>
                            ({{ duration }} {{ 'Days' | translate }})
                        </span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="buy-btn" >
                <button class="btn-buy">
                  <ng-container *ngIf="model.disabled; else active">
                    <i class="fa fa-ticket mr-1"></i>
                    <span>{{ buttonText | translate }}</span>
                  </ng-container>
                  <ng-template #active>
                    <ng-container *ngIf="model.ticketId">
                      <i class="fa fa-ticket mr-1"></i>
                      <span>{{ (isFree ? 'EVENTS.Register' : 'Buy') | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="!model.ticketId">
                      <i class="fa fa-search mr-1"></i>
                      <span>{{ 'SeeMore' | translate }}</span>
                    </ng-container>
                  </ng-template>
                </button>
            </div>
        </div>
    </div>
  </div>
