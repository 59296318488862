import { Component, ElementRef, EventEmitter, ViewChild, type OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { CommunityService } from 'src/app/services/community/community.service';
import { SwiperContainer } from 'swiper/element';
import { TicketsService } from './services/tickets.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import {CustomFieldDTO} from "../../../../DTO/CustomFieldDTO";
import { PurchaseResultDTO } from 'src/app/DTO/tickets/PurchaseResultDTO';
import { PaymentResult, PaymentsService } from 'src/app/services/payments/payments.service';
import { API_URL } from 'src/environments/environment';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {

  @ViewChild('swiper') private swiperRef: any;

  public communityId: number;
  public communityCode: string;
  public corpColor: string;
  public ticket: TicketDTO;
  public quantity: number;
  public totalPrice: number;
  public dateFrom: DateTime;
  public dateTo: DateTime;
  public fullFree: boolean;
  public onlyFree: boolean;
  public availableTickets: any;

  public canContinute = false;
  public formIsValid = false;

  public currentSlide: number = 0;
  //public focusableSlides = [0];
  //public currentSlideDelayed: number = 0;

  public isPurchaseLoading = false;
  //public clientSecret: string;
  public lang: string;
  //public paymentLoaded = true;
  //public paymentStep = false;
  public canPay: boolean;
  //public stripe: any;
  //public stripeElements: any;
  public paymentStarted = false;

  public isLoading = true;
  public swiperLoaded = false;
  public buttonLoading = false;
  public isFreePayment = false;
  public ephemeralKey: string;
  public isCustom: boolean;
  public customFieldsForm : CustomFieldDTO[];

  public privacyUrl: string;
  public termsUrl: string;

  private swiper: SwiperContainer;
  private userData = {};
  private ticketPurchaseUuid: string;
  private customCommunities = [
    'tortola-de-henares'
  ]

  public submitAttempt = new EventEmitter();

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private communityService: CommunityService,
    private ticketsService: TicketsService,
    public translate: TranslateService,
    private languageService: LanguageService,
    private ref: DynamicDialogRef,
    private el: ElementRef,
    private paymentsService: PaymentsService
  ) { }

  ngOnInit(): void {

    this.ref.onClose.subscribe(() => {
      this.totalPrice = 0;

    })

    this.ticket = this.dialogConfig.data.ticket;
    this.fullFree = this.ticket.prices.every(x => x.totalWithFee == 0);
    this.dateFrom = DateTime.fromJSDate(new Date(this.ticket.saleDateFrom));
    this.dateTo = DateTime.fromJSDate(new Date(this.ticket.saleDateTo));
    this.customFieldsForm = this.ticket.customFields.filter(x=> x.publicPermissions == 'rw');
    this.init();

    //this.privacyUrl = "https://wetown.app/{{this.translate.currentLang === 'es' ? 'es' : this.translate.currentLang === 'ca' ? 'ca':'ca'}}/politica-de-privacidad-app";
    //this.termsUrl = "https://wetown.app/{{}}/condicions-dus";

    let langQueryParam = ['es', 'ca', 'en'].includes(this.translate.currentLang) ? `?lang=${this.translate.currentLang}` : '';

    this.privacyUrl = `${API_URL}/${this.communityCode}/policies/privacy` + langQueryParam;
    this.termsUrl = `${API_URL}/${this.communityCode}/policies/terms` + langQueryParam;
  }

  public slideChange(event) {
    let oldIndex = this.currentSlide;
    this.currentSlide = event?.detail?.[0]?.activeIndex;
    //this.paymentStep = this.currentSlide == 2;
    /*
    setTimeout(() => {
      this.focusableSlides = this.focusableSlides.filter(x => x != oldIndex);
    }, 500);
    */
  }

  public changeStep(event, type) {
    switch(type) {
      case 'ticket':
        // .totalQty
        this.totalPrice = event.total;
        this.quantity = event.totalQty;
        this.canContinute = this.quantity > 0;
        break;
      case 'user':
        this.userData = event['user-data'];
        this.canContinute = true;
        if (this.userData && this.totalPrice != 0) {
          //this.paymentLoaded = false; STRIPE
        }
        break;
      case 'payment':
        this.canContinute = true;
        break;
    }
  }

  public next() {
    if (this.canContinute) {
      if (this.currentSlide > 0){

        this.submitAttempt.emit();

        if (!this.formIsValid){
          Swal.fire({
            position: "top" ,
            title: this.translate.instant('GENERIC.bad'),
            icon: 'warning',
            text: this.translate.instant('CheckFormFields'),
            confirmButtonText: this.translate.instant('Continue')
          });
          return;
        }

        if (this.currentSlide === 1) {
          this.purchase();
          return;
        }
      }

      //this.focusableSlides.push(this.swiper.swiper.activeIndex + 1);
      this.swiper.swiper.slideNext();
      this.canContinute = false;
    }
  }

  public prev() {
    //this.focusableSlides.push(this.swiper.swiper.activeIndex - 1);
    this.swiper.swiper.slidePrev();
    this.canContinute = true;
  }

  private purchase() {
    let details = this.ticket.prices
      .filter(x => x.qty && x.qty != 0)
      .map(x => ({
        quantity: x.qty,
        ticketPriceId: x.id
      }));

    if (this.totalPrice == 0){
      this.isFreePayment = true;
    }

    this.buttonLoading = true;

    this.ticketsService.purchase(this.communityCode, this.ticket.id, this.userData, details , this.customFieldsForm)
    .subscribe(async (res: PurchaseResultDTO) => {
      try {
        let paymentRes = await this.paymentsService.processPurchaseResult(res, null);

        this.buttonLoading = false;

        if (paymentRes == PaymentResult.SUCCESS){
          this.ref.close();
          this.successMessage(this.translate.instant('TICKETS.ReservedSuccess'), res.purchaseUuid);

          /*if (res.method){
            this.successMessage(this.translate.instant('TICKETS.BuySuccess'), res.purchaseUuid);
          } else {
            this.successMessage(this.translate.instant('TICKETS.ReservedSuccess'), res.purchaseUuid);
          }*/
        } else if (paymentRes == PaymentResult.FAILED){
          this.failedMessage(this.translate.instant('PAYMENT.PaymentFailure'));
        }
      } catch (error) {
        this.failedMessage();
      }


/*
      if (res.result == 'stripe') {
        console.log('stripe', res)
        this.stripe = await loadStripe(STRIPE_PUBKEY);
        this.paymentLoaded = true;
        this.clientSecret = res.stripeData.paymentIntent;
        this.ticketPurchaseUuid = res.ticketPurchaseUuid;

        const appearance = {
          theme: 'flat',
          variables: { colorPrimary: this.corpColor }
        };

        this.stripeElements = this.stripe.elements({
          'clientSecret': this.clientSecret,
          locale: this.lang,
          appearance
        });

      } else if (res.result == 'ok') {
        this.ref.close();

        this.isPurchaseLoading = false;

        Swal.fire({
          title: this.translate.instant('TICKETS.BuySuccess'),
          html: this.translate.instant('TICKETS.MailConfirmationSent') + `
          <br>
          <a target='_blank' href='/${this.communityCode}/tickets/print/${res.ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
            <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
          </a>`,
          icon: 'success',
          confirmButtonText: this.translate.instant('Accept'),
        })

      } else {

      }
      */
    }, (error) => {
      this.failedMessage();
    });
  }

  private successMessage(title, ticketPurchaseUuid){
    this.isPurchaseLoading = false;

    Swal.fire({
      title: title,
      html: this.translate.instant('TICKETS.MailConfirmationSent') + `
      <br>
      <a target='_blank' href='/${this.communityCode}/tickets/print/${ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
        <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
      </a>`,
      icon: 'success',
      confirmButtonText: this.translate.instant('Accept'),
    })
  }

  private async failedMessage(message?: string){
    this.isPurchaseLoading = false;

    Swal.fire({
      title: this.translate.instant('Oops'),
      text: message || this.translate.instant('ERRORS.Generic'),
      icon: 'error',
      confirmButtonText: this.translate.instant('Accept'),
    });
  }

  private async init() {
    this.communityService.Get()
    .subscribe((result) => {
      this.communityId = result.id;
      this.corpColor = result.color1;
      this.communityCode = result.code;
      this.isCustom = this.customCommunities.includes(this.communityCode);
      this.isLoading = false;
      setTimeout(() => this.initSwiper(), 0)

      this.languageService.getCurrentLang()
        .subscribe((language) => this.lang = language)
    });
  }

  private initSwiper() {
    this.swiperLoaded = true;
    setTimeout(() => this.swiper = document.querySelector('swiper-container'), 50)
  }
}
